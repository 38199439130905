import React from "react"
import ray from "../../images/about-us/ray.png"
import adam from "../../images/about-us/adam.png"
import ash from "../../images/about-us/ash.png"
import faisal from "../../images/about-us/faisal.png"
import jana from "../../images/about-us/jana.png"
import kellson from "../../images/about-us/kellson.png"
import leo from "../../images/about-us/leo.png"
import mark from "../../images/about-us/mark.png"
import omkar from "../../images/about-us/omkar.png"
import contact_img from "../../images/about-us/contact_img.jpg"
import location from "../../images/about-us/location.jpg"
import message from "../../images/about-us/message.png"
import chat from "../../images/about-us/chat.png"

export default function Team() {
  return (
    <div className="container team pb-16 sm:pt-16">
      <h2 className="heading-text">Meet Our Team </h2>
      <div className="flex justify-around flex-wrap mx-auto sm:flex-row flex-col ">
          <div className="sm:w-1/3 px-2">
            <div className="overflow-hidden relative">
              <div className="sm:mt-12">
                <div className=" ">
                  <img
                    src={ray}
                    alt="ray"
                    className="mx-auto object-cover object-top avatar-width"
                  />
                </div>
                <a href="https://www.linkedin.com/in/ray-wang-at-luca-plus/" target="_blank">
                  <svg
                    className="h-6 w-6 mx-auto my-4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="linkd"
                      d="M12 .48C5.639.48.48 5.638.48 12c0 6.362 5.158 11.52 11.52 11.52 6.363 0 11.52-5.158 11.52-11.52C23.52 5.638 18.363.48 12 .48zM9.18 16.775H6.848V9.268H9.18v7.507zM8 8.346c-.737 0-1.214-.522-1.214-1.168 0-.658.491-1.165 1.244-1.165.752 0 1.213.507 1.227 1.165 0 .646-.475 1.168-1.257 1.168zm9.7 8.429h-2.332v-4.16c0-.969-.339-1.627-1.182-1.627-.645 0-1.027.446-1.197.874-.062.152-.078.368-.078.583v4.329h-2.334v-5.112c0-.938-.03-1.721-.06-2.396h2.026l.107 1.043h.046c.308-.49 1.06-1.212 2.319-1.212 1.535 0 2.686 1.029 2.686 3.239v4.439z"
                      fill="#545A6C"
                      fillOpacity=".5"
                    />
                  </svg>
                </a>
                <div className="text-center mb-0 mt-0 sm:mt-4 text-gray-5 font-medium">
                  Ray Wang
                </div>
                <div className="text-center mt-0 mb-6 sm:mb-12 text-gray-5 mx-auto">
                  Co-founder/CEO
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-1/3 px-2">
            <div className="overflow-hidden relative">
              <div className="sm:mt-12">
                <div className=" ">
                  <img
                    src={ash}
                    alt="ray"
                    className="mx-auto object-cover object-top avatar-width"
                  />
                </div>
                <a href="" target="_blank">
                  <svg
                    className="h-6 w-6 mx-auto my-4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="linkd"
                      d="M12 .48C5.639.48.48 5.638.48 12c0 6.362 5.158 11.52 11.52 11.52 6.363 0 11.52-5.158 11.52-11.52C23.52 5.638 18.363.48 12 .48zM9.18 16.775H6.848V9.268H9.18v7.507zM8 8.346c-.737 0-1.214-.522-1.214-1.168 0-.658.491-1.165 1.244-1.165.752 0 1.213.507 1.227 1.165 0 .646-.475 1.168-1.257 1.168zm9.7 8.429h-2.332v-4.16c0-.969-.339-1.627-1.182-1.627-.645 0-1.027.446-1.197.874-.062.152-.078.368-.078.583v4.329h-2.334v-5.112c0-.938-.03-1.721-.06-2.396h2.026l.107 1.043h.046c.308-.49 1.06-1.212 2.319-1.212 1.535 0 2.686 1.029 2.686 3.239v4.439z"
                      fill="#545A6C"
                      fillOpacity=".5"
                    />
                  </svg>
                </a>
                <div className="text-center mb-0 mt-0 sm:mt-4 text-gray-5 font-medium">
                  Ash Shankar
                </div>
                <div className="text-center mt-0 mb-6 sm:mb-12 text-gray-5 mx-auto">
                  CTO
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-1/3 px-2">
            <div className="overflow-hidden relative">
              <div className="sm:mt-12">
                <div className=" ">
                  <img
                    src={leo}
                    alt="ray"
                    className="mx-auto object-cover object-top avatar-width"
                  />
                </div>
                <a href="https://www.linkedin.com/in/leonard-huang-a1350b69/" target="_blank">
                  <svg
                    className="h-6 w-6 mx-auto my-4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="linkd"
                      d="M12 .48C5.639.48.48 5.638.48 12c0 6.362 5.158 11.52 11.52 11.52 6.363 0 11.52-5.158 11.52-11.52C23.52 5.638 18.363.48 12 .48zM9.18 16.775H6.848V9.268H9.18v7.507zM8 8.346c-.737 0-1.214-.522-1.214-1.168 0-.658.491-1.165 1.244-1.165.752 0 1.213.507 1.227 1.165 0 .646-.475 1.168-1.257 1.168zm9.7 8.429h-2.332v-4.16c0-.969-.339-1.627-1.182-1.627-.645 0-1.027.446-1.197.874-.062.152-.078.368-.078.583v4.329h-2.334v-5.112c0-.938-.03-1.721-.06-2.396h2.026l.107 1.043h.046c.308-.49 1.06-1.212 2.319-1.212 1.535 0 2.686 1.029 2.686 3.239v4.439z"
                      fill="#545A6C"
                      fillOpacity=".5"
                    />
                  </svg>
                </a>
                <div className="text-center mb-0 mt-0 sm:mt-4 text-gray-5 font-medium">
                  Leo Huang
                </div>
                <div className="text-center mt-0 mb-6 sm:mb-12 text-gray-5 mx-auto">
                  Co-founder/Chairman
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-1/3 px-2">
            <div className="overflow-hidden relative">
              <div className="sm:mt-12">
                <div className=" ">
                  <img
                    src={omkar}
                    alt="ray"
                    className="mx-auto object-cover object-top avatar-width"
                  />
                </div>
                <a href="https://www.linkedin.com/in/omkar-sangaraju-44428a159/" target="_blank">
                  <svg
                    className="h-6 w-6 mx-auto my-4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="linkd"
                      d="M12 .48C5.639.48.48 5.638.48 12c0 6.362 5.158 11.52 11.52 11.52 6.363 0 11.52-5.158 11.52-11.52C23.52 5.638 18.363.48 12 .48zM9.18 16.775H6.848V9.268H9.18v7.507zM8 8.346c-.737 0-1.214-.522-1.214-1.168 0-.658.491-1.165 1.244-1.165.752 0 1.213.507 1.227 1.165 0 .646-.475 1.168-1.257 1.168zm9.7 8.429h-2.332v-4.16c0-.969-.339-1.627-1.182-1.627-.645 0-1.027.446-1.197.874-.062.152-.078.368-.078.583v4.329h-2.334v-5.112c0-.938-.03-1.721-.06-2.396h2.026l.107 1.043h.046c.308-.49 1.06-1.212 2.319-1.212 1.535 0 2.686 1.029 2.686 3.239v4.439z"
                      fill="#545A6C"
                      fillOpacity=".5"
                    />
                  </svg>
                </a>
                <div className="text-center mb-0 mt-0 sm:mt-4 text-gray-5 font-medium">
                  Omkar Sangaraju
                </div>
                <div className="text-center mt-0 mb-6 sm:mb-12 text-gray-5 mx-auto">
                  Tech Lead
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-1/3 px-2">
            <div className="overflow-hidden relative">
              <div className="sm:mt-12">
                <div className=" ">
                  <img
                    src={kellson}
                    alt="ray"
                    className="mx-auto object-cover object-top avatar-width"
                  />
                </div>
                <a href="https://www.linkedin.com/in/kellson/" target="_blank">
                  <svg
                    className="h-6 w-6 mx-auto my-4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="linkd"
                      d="M12 .48C5.639.48.48 5.638.48 12c0 6.362 5.158 11.52 11.52 11.52 6.363 0 11.52-5.158 11.52-11.52C23.52 5.638 18.363.48 12 .48zM9.18 16.775H6.848V9.268H9.18v7.507zM8 8.346c-.737 0-1.214-.522-1.214-1.168 0-.658.491-1.165 1.244-1.165.752 0 1.213.507 1.227 1.165 0 .646-.475 1.168-1.257 1.168zm9.7 8.429h-2.332v-4.16c0-.969-.339-1.627-1.182-1.627-.645 0-1.027.446-1.197.874-.062.152-.078.368-.078.583v4.329h-2.334v-5.112c0-.938-.03-1.721-.06-2.396h2.026l.107 1.043h.046c.308-.49 1.06-1.212 2.319-1.212 1.535 0 2.686 1.029 2.686 3.239v4.439z"
                      fill="#545A6C"
                      fillOpacity=".5"
                    />
                  </svg>
                </a>
                <div className="text-center mb-0 mt-0 sm:mt-4 text-gray-5 font-medium">
                  Kellson Ma
                </div>
                <div className="text-center mt-0 mb-6 sm:mb-12 text-gray-5 mx-auto">
                  CIO
                </div>
              </div>
            </div>
          </div>
          <div className="sm:w-1/3 px-2">
            <div className="overflow-hidden relative">
              <div className="sm:mt-12">
                <div className=" ">
                  <img
                    src={adam}
                    alt="ray"
                    className="mx-auto object-cover object-top avatar-width"
                  />
                </div>
                <a href="https://www.linkedin.com/in/adam-wang-0a218819/" target="_blank">
                  <svg
                    className="h-6 w-6 mx-auto my-4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="linkd"
                      d="M12 .48C5.639.48.48 5.638.48 12c0 6.362 5.158 11.52 11.52 11.52 6.363 0 11.52-5.158 11.52-11.52C23.52 5.638 18.363.48 12 .48zM9.18 16.775H6.848V9.268H9.18v7.507zM8 8.346c-.737 0-1.214-.522-1.214-1.168 0-.658.491-1.165 1.244-1.165.752 0 1.213.507 1.227 1.165 0 .646-.475 1.168-1.257 1.168zm9.7 8.429h-2.332v-4.16c0-.969-.339-1.627-1.182-1.627-.645 0-1.027.446-1.197.874-.062.152-.078.368-.078.583v4.329h-2.334v-5.112c0-.938-.03-1.721-.06-2.396h2.026l.107 1.043h.046c.308-.49 1.06-1.212 2.319-1.212 1.535 0 2.686 1.029 2.686 3.239v4.439z"
                      fill="#545A6C"
                      fillOpacity=".5"
                    />
                  </svg>
                </a>
                <div className="text-center mb-0 mt-0 sm:mt-4 text-gray-5 font-medium">
                  Adam Wang
                </div>
                <div className="text-center mt-0 mb-6 sm:mb-12 text-gray-5 mx-auto">
                  Co-founder
                </div>
              </div>
            </div>
          </div>
          {/*<div className="sm:w-1/3 px-2">*/}
          {/*  <div className="overflow-hidden relative">*/}
          {/*    <div className="sm:mt-12">*/}
          {/*      <div className=" ">*/}
          {/*        <img*/}
          {/*          src={jana}*/}
          {/*          alt="ray"*/}
          {/*          className="mx-auto object-cover object-top avatar-width"*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*      <a href="https://www.linkedin.com/in/dr-jana-schmitz-96847144/" target="_blank">*/}
          {/*        <svg*/}
          {/*          className="h-6 w-6 mx-auto my-4"*/}
          {/*          xmlns="http://www.w3.org/2000/svg"*/}
          {/*        >*/}
          {/*          <path*/}
          {/*            className="linkd"*/}
          {/*            d="M12 .48C5.639.48.48 5.638.48 12c0 6.362 5.158 11.52 11.52 11.52 6.363 0 11.52-5.158 11.52-11.52C23.52 5.638 18.363.48 12 .48zM9.18 16.775H6.848V9.268H9.18v7.507zM8 8.346c-.737 0-1.214-.522-1.214-1.168 0-.658.491-1.165 1.244-1.165.752 0 1.213.507 1.227 1.165 0 .646-.475 1.168-1.257 1.168zm9.7 8.429h-2.332v-4.16c0-.969-.339-1.627-1.182-1.627-.645 0-1.027.446-1.197.874-.062.152-.078.368-.078.583v4.329h-2.334v-5.112c0-.938-.03-1.721-.06-2.396h2.026l.107 1.043h.046c.308-.49 1.06-1.212 2.319-1.212 1.535 0 2.686 1.029 2.686 3.239v4.439z"*/}
          {/*            fill="#545A6C"*/}
          {/*            fillOpacity=".5"*/}
          {/*          />*/}
          {/*        </svg>*/}
          {/*      </a>*/}
          {/*      <div className="text-center mb-0 mt-0 sm:mt-4 text-gray-5 font-medium">*/}
          {/*        Dr.Jana*/}
          {/*      </div>*/}
          {/*      <div className="text-center mt-0 mb-6 sm:mb-12 text-gray-5 mx-auto">*/}
          {/*        Advisor*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        <div className="sm:w-1/3 px-2">
          <div className="overflow-hidden relative">
            <div className="sm:mt-12">
              <div className=" ">
                <img
                  src={faisal}
                  alt="ray"
                  className="mx-auto object-cover object-top avatar-width"
                />
              </div>
              <a href="https://www.linkedin.com/in/faisalmehmood10/" target="_blank">
                <svg
                  className="h-6 w-6 mx-auto my-4"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="linkd"
                    d="M12 .48C5.639.48.48 5.638.48 12c0 6.362 5.158 11.52 11.52 11.52 6.363 0 11.52-5.158 11.52-11.52C23.52 5.638 18.363.48 12 .48zM9.18 16.775H6.848V9.268H9.18v7.507zM8 8.346c-.737 0-1.214-.522-1.214-1.168 0-.658.491-1.165 1.244-1.165.752 0 1.213.507 1.227 1.165 0 .646-.475 1.168-1.257 1.168zm9.7 8.429h-2.332v-4.16c0-.969-.339-1.627-1.182-1.627-.645 0-1.027.446-1.197.874-.062.152-.078.368-.078.583v4.329h-2.334v-5.112c0-.938-.03-1.721-.06-2.396h2.026l.107 1.043h.046c.308-.49 1.06-1.212 2.319-1.212 1.535 0 2.686 1.029 2.686 3.239v4.439z"
                    fill="#545A6C"
                    fillOpacity=".5"
                  />
                </svg>
              </a>
              <div className="text-center mb-0 mt-0 sm:mt-4 text-gray-5 font-medium">
                Faisal Mehmood
              </div>
              <div className="text-center mt-0 mb-6 sm:mb-12 text-gray-5 mx-auto">
                Advisor
              </div>
            </div>
          </div>
        </div>
        <div className="sm:w-1/3 px-2">
            <div className="overflow-hidden relative">
              <div className="sm:mt-12">
                <div className=" ">
                  <img
                    src={mark}
                    alt="ray"
                    className="mx-auto object-cover object-top avatar-width"
                  />
                </div>
                <a href="https://www.linkedin.com/in/mark-glikson-6743192/" target="_blank">
                  <svg
                    className="h-6 w-6 mx-auto my-4"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="linkd"
                      d="M12 .48C5.639.48.48 5.638.48 12c0 6.362 5.158 11.52 11.52 11.52 6.363 0 11.52-5.158 11.52-11.52C23.52 5.638 18.363.48 12 .48zM9.18 16.775H6.848V9.268H9.18v7.507zM8 8.346c-.737 0-1.214-.522-1.214-1.168 0-.658.491-1.165 1.244-1.165.752 0 1.213.507 1.227 1.165 0 .646-.475 1.168-1.257 1.168zm9.7 8.429h-2.332v-4.16c0-.969-.339-1.627-1.182-1.627-.645 0-1.027.446-1.197.874-.062.152-.078.368-.078.583v4.329h-2.334v-5.112c0-.938-.03-1.721-.06-2.396h2.026l.107 1.043h.046c.308-.49 1.06-1.212 2.319-1.212 1.535 0 2.686 1.029 2.686 3.239v4.439z"
                      fill="#545A6C"
                      fillOpacity=".5"
                    />
                  </svg>
                </a>
                <div className="text-center mb-0 mt-0 sm:mt-4 text-gray-5 font-medium">
                  Mark Glikson
                </div>
                <div className="text-center mt-0 mb-6 sm:mb-12 text-gray-5 mx-auto">
                  Advisor
                </div>
              </div>
            </div>
          </div>
      </div>

      <div className="contact-us flex content-wrap sm:flex-row  flex-col pt-16">
        <div className="left">
          <img src={contact_img} className="rectangle-img"/>
        </div>
        <div className="right">
          <div className="heading-text section left-align"> Contact US </div>
          <div className="flex section">
            <img src={location} className="location-img"/>
            <span className="contact-us-text" >121 King St, Melbourne VIC 3000</span>
          </div>
          <div className="flex section">
            <img src={message} />
            <span className="contact-us-text" >+61 (03) 9005 5308 </span>
          </div>
          <div className="flex section">
            <img src={chat} />
            <span className="contact-us-text" >support@lucaplus.com</span>
          </div>
        </div>
      </div>

    </div>
  )
}
