import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import About from "../components/about-us/about"
import Graph from "../components/about-us/graph"
import Vision from "../components/about-us/vision"
import Value from "../components/about-us/value"
import Team from "../components/about-us/team"
import Modali, { useModali } from "../components/modal"
import { Link } from "gatsby"

const AboutUs = () => {
  const [existingOrgModal, toggleExistingOrgModal] = useModali();
  const [selectedOption, handleSelectedOption] = useState("")

  return (
    <Layout headerFixed={true} page="About" toggleExistingOrgModal={toggleExistingOrgModal}>
      <SEO title="About LUCA+ E-invoicing Company | LUCA+ w/ (XERO, MYOB, QuickBooks)" description=" LUCA+ is a new Australian E-invoicing company. The LUCA+ platform helps you save time and money whilst eliminating fraud and scams in your business invoicing."
           keywords="about e-invoicing company" />
      <div className="about-us">
        <About />
        {/*<Graph />*/}
        <Vision />
        <Value />
        <Team />
      </div>
      <Modali.Modal {...existingOrgModal} className=" error-modal exist-org-modal" >
        <div className="pt-4">
          <div className="pop-up-inner mx-auto">
            <div className="mt-8 mb-12 flex flex-col">
              <Link
                to={`/sign-up?plan=combined&lucapay=true&fromHomePage=true`}
                state={{ isTrial: true, fromHomePage: true }}
                className="popup-box cursor-pointer"
              >
                <input
                  className="option cursor-pointer"
                  type="radio"
                  value="xero"
                  name="option"
                  checked={selectedOption === "xero"}
                  onClick={() => handleSelectedOption("xero")}
                />
                <label className="ml-5 w-10/12cursor-pointer" htmlFor="option-1">
                  I use Xero, MYOB, Quickbooks
                </label>
              </Link>
              <Link
                to={`/sign-up?plan=partner&lucapay=true&fromHomePage=true`}
                state={{ isTrial: true, fromHomePage: true }}
                className="popup-box cursor-pointer mt-5"
              >
                <input
                  type="radio"
                  value="lucaplus"
                  name="option"
                  checked={selectedOption === "lucaplus"}
                  onClick={() => handleSelectedOption("lucaplus")}
                />
                <label className="w-10/12 ml-5 cursor-pointer" htmlFor="option-1">
                  I use other accounting software
                </label>
              </Link>
              <Link
                to={`/sign-up?plan=partner&lucapay=true&lucapayLite=true&fromHomePage=true`}
                state={{ isTrial: true }}
                className="popup-box cursor-pointer mt-5"
              >
                <input
                  type="radio"
                  value="lucaplus"
                  name="option"
                  checked={selectedOption === "lucaplus"}
                  onClick={() => handleSelectedOption("lucaplus")}
                />
                <label className="w-10/12 ml-5 cursor-pointer" htmlFor="option-1">
                  I don‘t have any accounting software
                </label>
              </Link>
            </div>
          </div>
        </div>
      </Modali.Modal>
    </Layout>
  )
}

export default AboutUs
