import React from "react"
import wave_1 from "../../images/about-us/wave_1.svg"
import vector from "../../images/about-us/vector.svg"
import img1 from "../../images/about-us/img1.jpg"
import img2 from "../../images/about-us/img2.jpg"

export default function About() {
  return (
    <div className="container">
      <div className="business-wrap">
        <div className="sm:flex-row sm:pt-32 pt-32 pb-4">
          <h1 className="title"> About Us </h1>
          <img src={wave_1} className="img-wave sm:block hidden" />
        </div>
        <div className="flex relative sm:items-center items-start content-wrap sm:flex-row flex-col text-formatting sm:mt-8 sm:pb-20 pb-10">
          <div className="left">
            Revolutionizing Business Credit for Growth and Success
            <br />
            <br />
            Welcome to LUCA Plus. We provide innovative cash flow solutions for small businesses to boost their cash flow,
            by enhancing their purchasing power, enabling them to get paid faster, and accelerating their growth through advanced technology.
            <br />
            <br />
            Our expertise and our understanding of our customers have allowed us to develop a solution attuned to your needs. When you have cash flow issues,
            you don’t need the approval process to take months. Using our innovative systems, we extend a line of credit to you within several hours, meaning
            that we can ease your cash flow instantly.
            <br />
            <br />
            And as a B2B provider, our service doesn’t stop there. You can extend this service to your customers and clients so that their projects aren’t held up in the pipeline
            - a win for them and for you.
          </div>
          <div className="right sm:block hidden">
            <img src={vector} className="flex margin-auto"/>
          </div>
        </div>

        <div className="flex content-wrap sm:flex-row social-imgs">
          <div className="left">
            <section className="h-screen z-0 relative">
            </section>
            <div className="w-3/5 mr-auto ">
              <div
                className="container bg-white card-border left__card  antialiased overflow-hidden  mx-auto z-20 relative mb-6 sm:px-10 pb-12 rounded-0"
              >
                <div className="m-5 pt-5">
                  <div className="text-our-story"> Our Story </div>
                  <div className="text-story"> From accounting to a fintech start-up</div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="right">*/}
          {/*  <section className="h-screen bg-mint z-0 relative">*/}
          {/*  </section>*/}
          {/*</div>*/}
        </div>

        <div className="flex content-wrap sm:flex-row flex-col sm:mt-16 pb-16">
          <div className="text">
            In his lifetime LUCA Pacioli, the father of accounting, invented the double ledger accounting
              system which fundamentally altered global business practices and revolutionised the entire finance and accounting industry.<br /><br />

            In this age of Bitcoin and blockchain technology, our founders, also passionate accountants recognised that with this
            new technology, it was now possible to enhance credibility and transparency in the credit risk approval process, and formed a
            team with industry experts to develop the triple ledger system, thus LUCA Plus was born<br /><br />

            </div>
        </div>
      </div>
    </div>
  )
}
