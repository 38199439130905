import React from "react"
import shake_hands from "../../images/about-us/shake_hands.jpg"
import money_up from "../../images/about-us/money_up.jpg"
import global from "../../images/about-us/global.jpg"
import visionImg from "../../images/about-us/vision.svg"

export default function Vision() {
  return (
    <div className="container vision">
      <div className="business-wrap">
        <div className="items-center content-wrap">
          {/*<span className="vision-text"> Our Vision </span>*/}
          <h2 className="heading-text">Our Vision </h2>
        </div>
        <div className={"text mt-3 text-center"}>
          LUCA Plus is creating the future of business, breaking away from the traditional model to reshape the way credit and
          investments work, and creating efficient cash flow solutions that enable businesses to have a bigger, brighter future.
          From AI-driven risk assessments to the blockchain, we're continually setting new industry standards with simple, flexible, and reliable credit solutions powered by cutting-edge technology.
        </div>
        <div className="w-100 flex justify-content-center pt-10">
          <img src={visionImg} />
        </div>
      </div>
    </div>
  )
}
