import React from "react"
import graph_value1 from "../../images/about-us/graph_value1.jpg"
import graph_value2 from "../../images/about-us/graph_value2.jpg"
import graph_value3 from "../../images/about-us/graph_value3.png"
import graph_value4 from "../../images/about-us/graph_value4.jpg"
import accountancy_software_logo from "../../images/about-us/accountancy_software_logo.png"
import fintech_awards_logo from "../../images/about-us/fintech_awards_logo.png"
import jumpstarter_logo from "../../images/about-us/jumpstarter_logo.png"
import mel_accelerator_program_logo from "../../images/about-us/mel_accelerator_program_logo.png"
import StartupScaleupOfTheYear from "../../images/about-us/startup_scaleup_of_the_year._award.png"
import vector_img from "../../images/about-us/vector-img.png"
import rectangle from "../../images/about-us/rectangle.svg"

export default function Value() {
  return (
    <div className="container pb-36 top-value-page">
      <div className="mission flex content-wrap sm:flex-row flex-col sm:pt-16 pt-8">
        <div className="left">
          {/*<span className="mission-text "> Our Mission </span>*/}
          <h2 className="heading-text">Our Mission</h2>
          <span className="text">At LUCA Plus, we are committed to providing a seamless user experience and easy access to credit with best-in-class cash flow solutions,
            so that businesses can grow and achieve sustainable success.
          </span>
        </div>
        <div className="right">
          <img src={rectangle} className="rectangle-img"/>
        </div>
      </div>
      <div className="business-wrap value-page sm:pb-16 pb-1">
        <section className="h-screen bg-mint z-0 relative">
        </section>
        <div
          className="bg-white
          card-border value-page__card  antialiased overflow-hidden mx-auto z-20 relative mb-6 px-10 md:px-10 pb-12 sm:pb-32"
        >
          <h2 className="value-title font-bold mt-6 sm:mt-10 mb-10 sm:mb-18 sm:block">
            Our Value
          </h2>
          <div className="flex justify-between sm:flex-row flex-col">
            <div className="box">
              <div className="feat-sec-img m-15">
                <div className="icon-bg"><img src={graph_value1} id="icon-bg" /></div>
                <div className="text-brand sm:my-3 mt-5 mb-3 text-15 sm:text-18 font-medium t1">
                  Focus
                </div>
                <div className="text-neutral-3 text-16 sm:text-15">
                We believe the world deserves a more efficient way of conducting business
                </div>
              </div>
            </div>
            <div className="box sm:mt-0 mt-8">
              <div className="feat-sec-img m-15">
                <div className="icon-bg" id="icon-bg"><img src={graph_value2} /></div>
                <div className="text-brand sm:my-3 mt-5 mb-3 text-15 sm:text-18 font-medium t1">
                  Attitude
                </div>
                <div className="text-neutral-3 text-16 sm:text-15 paragraph">
                We have positive attitude to appreciate, learn, help each other and grow
                </div>
              </div>
            </div>
            <div className="box sm:mt-0 mt-8">
              <div className="feat-sec-img m-15">
                <div className="icon-bg" id="icon-bg"><img src={graph_value3} /></div>
                <div className="text-brand sm:my-3 mt-5 mb-3 text-15 sm:text-18 font-medium t1">
                  Simple
                </div>
                <div className="text-neutral-3 text-16 sm:text-15 paragraph">
                  We are passionate in simplifying accounting solutions for better business
                </div>
              </div>
            </div>
            <div className="box sm:mt-0 mt-8">
              <div className="feat-sec-img m-15">
                <div className="icon-bg" id="icon-bg"><img src={graph_value4} /></div>
                <div className="text-brand sm:my-3 mt-5 mb-3 text-15 sm:text-18 font-medium t1">
                  Transparent
                </div>
                <div className="text-neutral-3 text-16 sm:text-15 paragraph">
                  We value authenticity among each other which leads to team trust
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="award flex content-wrap sm:flex-row flex-col">
        <div className="left">
          <div className="sm:hidden pt-8">
            <h1 className="award-title">
              LUCA Plus Awards and Recognition
            </h1>
            <div className="award-text"> We believe the world deserves a more efficient way of conducting business. </div>
          </div>
          <div className="sm:flex grid justify-content-center">
            <div className="box">
              <div className="feat-sec-img">
                <div className="icon-bg"><img src={accountancy_software_logo} className="margin-auto" /></div>
                <div className="text-neutral-3 text-16 sm:text-15">
                2019 ‘Accountancy Software of the Year’ Finalist
                </div>
              </div>
            </div>
            <div className="box">
              <div className="feat-sec-img">
                <div className="icon-bg"><img src={jumpstarter_logo} className="margin-auto" /></div>
                <div className="text-neutral-3 text-16 sm:text-15">
                2020 Global Finalist for the Jumpstarter Pitch Competition
                </div>
              </div>
            </div>
          </div>
          <div className="sm:flex grid justify-content-center">
            <div className="box">
              <div className="feat-sec-img">
                <div className="icon-bg"><img src={StartupScaleupOfTheYear} className="margin-auto h-full" /></div>
                <div className="text-neutral-3 text-16 sm:text-15">
                Startup or Scaleup of the Year Awards Finalist
                </div>
              </div>
            </div>
            <div className="box">
              <div className="feat-sec-img">
                <div className="icon-bg"><img src={fintech_awards_logo} className="margin-auto" /></div>
                <div className="text-neutral-3 text-16 sm:text-15">
                2020 Australia Fintech Awards Finalist
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right sm:block hidden">
          <div className="right-div">
            <h2 className="value-title mt-6 sm:mt-10">
              LUCA Plus Awards and Recognition
            </h2>
            <span className="award-text"> We believe the world deserves a more efficient way of conducting business. </span>
          </div>
          <img src={vector_img} className="vector-img"/>
        </div>
      </div>

    </div>
  )
}
